<template>
    <div style="width: 100%;height: 100vh;background-color: white;display: flex;flex-direction: column;">
        <Header :title="'评论详情'" :leftClick="goBack">
        </Header>
        <div style="width: 100%;height: 1px;background-color: #F0F1F5;"></div>
        <div style="flex: 1;padding: 20px 20px;box-sizing: border-box;overflow-y: auto;">
            <div
                style="display: flex;background-color: #f5f5f7;border-radius: 12px;padding: 16px 0px 16px 16px;box-sizing: border-box;align-items: center;">
                <img :src="albumInfo.cover_pic_url" style="width: 64px;height: 64px;border-radius: 8px;">
                <div style="flex: 1;width: 100%;padding-left: 15px;box-sizing: border-box;">
                    <p style="color: #131415;font-size: 18px;font-weight: bold;">{{ albumInfo.album_name }}</p>
                    <p style="color: #454651;font-size: 14px;margin-top: 10px;">{{ songInfo.song_name }}</p>
                </div>
            </div>
            <p style="color: #333333;font-size: 16px;margin-top: 20px;">评论列表</p>
            <div v-if="dataList.length>0">
                <div v-for="(comment, index) in dataList" :key="index" class="comment-item">
                <img src="../../assets/app/icon_default_avator.png" style="width: 40px;height: 40px;">
                <div style="flex: 1;padding-left: 15px;box-sizing: border-box;">
                    <div style="display: flex;align-items: center;">
                        <p style="flex: 1;color: #454651;font-size: 15px;">{{ formatUserMobile }}</p>
                        <img src="../../assets/app/delete.png" style="width: 21px;height: auto;cursor: pointer;"
                            @click="deleteComment(comment)">
                        <p style="color: #9698a2;font-size: 13px;margin-left: 3px;cursor: pointer;"
                            @click="deleteComment(comment)">删除</p>
                    </div>
                    <p style="color: #9698a2;font-size: 13px;margin-top: 5px;">{{ formatCommentTime(comment.create_time) }}
                    </p>
                    <p style="color: #262c3a;font-size: 14px;margin-top: 5px;">{{ comment.reply_content }}</p>
                </div>
            </div>
            </div>
            <el-empty v-else></el-empty>
        </div>
        <div style="display: flex;padding: 20px;box-sizing: border-box;align-items: center;align-items: center;">
            <el-input style="flex: 1;" v-model="contentInfo" placeholder="请输入"></el-input>
            <div class="btn-comment" @click="confirmSendComment">发表</div>
        </div>
    </div>
</template>
<script>
import {
    getAlbum,
    getSong,
    getRepliesBySong,
    deleteReply,
    addReply,
    getUserByUserID
} from '../../api/app'
import {
    getAppUserId
} from '../../utils/store'
import {
    formatDate
} from '../../utils/date'

import Header from "../../components/app/Header.vue";

export default ({
    name: 'index',
    components: {
        Header,
    },
    data() {
        return {
            user_id: getAppUserId(),
            song_id: this.$route.query.song_id,
            dataList: [],
            songInfo: {},
            albumInfo: {},
            contentInfo: '',
            formatUserMobile: ''
        }
    },
    mounted() {
        getUserByUserID(this.user_id).then((res) => {
            let result = res.data.data
            let user_mobile = result.user_mobile
            this.formatUserMobile = user_mobile.substring(0, 3) + '****' + user_mobile.substring(7, user_mobile.length)
        })
        this.fetchData()
    },
    methods: {
        async fetchData() {
            let songResult = await getSong(this.song_id, 'album')
            this.songInfo = songResult.data.data

            let album_id = this.songInfo.album_id
            let albumResult = await getAlbum(album_id, this.user_id)
            this.albumInfo = albumResult.data.data
            let replySongsResult = await getRepliesBySong(this.song_id)
            if (replySongsResult.data.code == 0) {
                this.dataList = replySongsResult.data.data
            }
        },
        formatCommentTime(create_time) {
            return formatDate(new Date(create_time * 1000), 'Y/mm/dd hh:ss')
        },
        deleteComment(comment) {
            deleteReply(comment._id.$id, this.user_id).then((res) => {
                let result = res.data
                if (result.code == 0) {
                    this.$message.success('删除成功')
                    this.fetchData()
                } else {
                    this.$message.success(result.msg)
                }
            })
        },
        goBack() {
            this.$router.go(-1)
        },
        confirmSendComment() {
            if (this.contentInfo == '') {
                this.$message.warning('请输入内容')
                return
            }
            addReply(this.song_id, this.user_id, this.contentInfo).then((res) => {
                let result = res.data
                if (result.code == 0) {
                    this.$message.success('评论成功')
                    this.fetchData()
                    this.contentInfo = ''
                } else {
                    this.$message.success(result.msg)
                }
            })
        }
    }
})
</script>

<style lang="less" scoped>
/deep/ .el-input__inner {
    border-color: #5753b0;
    color: #5753b0;
}

.btn-comment {
    cursor: pointer;
    height: 42px;
    line-height: 42px;
    text-align: center;
    color: white;
    border-radius: 8px;
    font-size: 16px;
    padding: 0px 24px;
    margin-left: 10px;
    background-color: #5753b0;
}

.comment-item {
    width: 100%;
    display: flex;
    padding: 20px 0px;
    border-bottom: 1px solid #F0F1F5;
}
</style>