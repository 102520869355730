import request from '../utils/AppRequest'


export function getUserByUserID(user_id) {
    return request.post('./index.php', {
        function: 'getUserByUserID',
        user_id
    })
}

export function getKidWantAlbums(user_id) {
    return request.post('./index.php', {
        function: 'getKidWantAlbums',
        user_id
    })
}

export function getHomepageTab(user_type) {
    return request.post('./index.php', {
        function: 'getHomepageTab',
        user_type
    })
}

export function refuseKidWantAlbum(want_id) {
    return request.post('./index.php', {
        function: 'refuseKidWantAlbum',
        want_id
    })
}

export function getCollection(collection_id) {
    return request.post('./index.php', {
        function: 'getCollection',
        collection_id
    })
}


export function getDefaultKid(user_id) {
    return request.post('./index.php', {
        function: 'getDefaultKid',
        user_id
    })
}

export function getHomepage(homepage_id) {
    return request.post('./index.php', {
        function: 'getHomepage',
        homepage_id
    })
}
export function fetchDataByGivenUrl(params) {
    return request.post('./index.php', params)
}

export function getSongsByAlbum(album_id, user_id) {
    return request.post('./index.php', {
        function: 'getSongsByAlbum',
        album_id,
        user_id
    })
}
export function getAlbum(album_id, user_id) {
    return request.post('./index.php', {
        function: 'getAlbum',
        album_id,
        user_id
    })
}

export function addPurchase(params) {
    return request.post('./index.php', params)
}

export function getAndroidPricesByProduct() {
    return request.post('./index.php', {
        function: 'getAndroidPricesByProduct',
    })
}

export function createProductOrder(price_id) {
    return request.post('./index.php', {
        function: 'createProductOrder',
        price_id
    })
}

export function wx_pay_fwh(_id) {
    return request.post('./index.php', {
        function: 'wx_pay_fwh',
        _id
    })
}
export function findKidsByUser(user_id) {
    return request.post('./index.php', {
        function: 'findKidsByUser',
        user_id
    })
}
export function getNextLevelPoints(level) {
    return request.post('./index.php', {
        function: 'getNextLevelPoints',
        level
    })
}

export function getUserSessionSetting() {
    return request.post('./index.php', {
        function: 'getUserSessionSetting'
    })
}

export function getSessionsRank(session_count) {
    return request.post('./index.php', {
        function: 'getSessionsRank',
        session_count
    })
}

export function getUserWeekSessions(week_time_stamp) {
    return request.post('./index.php', {
        function: 'getUserWeekSessions',
        week_time_stamp
    })
}

export function updateUserSessionSetting(params) {
    return request.post('./index.php', {
        function: 'updateUserSessionSetting',
        user_id: params.user_id,
        setting_id: params.setting_id,
        time_slots: params.time_slots
    })
}

export function refreshFutureSessionArrangement_NEW() {
    return request.post('./index.php', {
        function: 'refreshFutureSessionArrangement_NEW'
    })
}

export function getUserAllPurchased(time_stamp) {
    return request.post('./index.php', {
        function: 'getUserAllPurchased',
        time_stamp
    })
}

export function getContentWeightSetting() {
    return request.post('./index.php', {
        function: 'getContentWeightSetting'
    })
}

export function updateContentWeightSetting(params) {
    return request.post('./index.php', {
        function: 'updateContentWeightSetting',
        user_id: params.user_id,
        content_id: params.content_id,
        content_type: params.content_type,
        content_weight: params.content_weight,
        content_subject: params.content_subject,
        arrange_type: params.arrange_type
    })
}

export function getMyPurchases(user_id, time_stamp) {
    return request.post('./index.php', {
        function: 'getMyPurchases',
        user_id,
        time_stamp
    })
}

export function getMyPurchasesV1(user_id) {
    return request.post('./index.php', {
        function: 'getMyPurchasesV1',
        user_id
    })
}

export function getKechengMustRead() {
    return request.post('./index.php', {
        function: 'getKechengMustRead'
    })
}

export function updateKid(user_id, kid_id, kid_name) {
    return request.post('./index.php', {
        function: 'updateKid',
        user_id,
        kid_id,
        kid_name
    })
}

export function getExpTaskRecordsV1(user_id) {
    return request.post('./index.php', {
        function: 'getExpTaskRecordsV1',
        user_id
    })
}

export function getExpTaskCategories() {
    return request.post('./index.php', {
        function: 'getExpTaskCategories'
    })
}

export function getExpTaskList(category) {
    return request.post('./index.php', {
        function: 'getExpTaskList',
        category
    })
}

export function getUserKenengPointsBalance() {
    return request.post('./index.php', {
        function: 'getUserKenengPointsBalance'
    })
}

export function getMyOrders() {
    return request.post('./index.php', {
        function: 'getMyOrders'
    })
}


export function findMyMessages() {
    return request.post('./index.php', {
        function: 'findMyMessages'
    })
}


export function sendMessage(text) {
    return request.post('./index.php', {
        function: 'sendMessage',
        text
    })
}

export function reporting(user_id, content) {
    return request.post('./index.php', {
        function: 'reporting',
        user_id,
        content
    })
}

export function getSong(song_id, from) {
    return request.post('./index.php', {
        function: 'getSong',
        song_id,
        from
    })
}

export function favoriteSong(song_id) {
    return request.post('./index.php', {
        function: 'favoriteSong',
        song_id
    })
}

export function getRecentSongs() {
    return request.post('./index.php', {
        function: 'getRecentSongs'
    })
}

export function getFavoriteSongsV1(user_id, kid_id) {
    return request.post('./index.php', {
        function: 'getFavoriteSongsV1',
        user_id,
        kid_id
    })
}

export function getMyQuizRecordsV1(user_id, kid_id) {
    return request.post('./index.php', {
        function: 'getMyQuizRecordsV1',
        user_id,
        kid_id
    })
}

export function getRepliesBySong(song_id) {
    return request.post('./index.php', {
        function: 'getRepliesBySong',
        song_id
    })
}

export function deleteReply(reply_id, user_id) {
    return request.post('./index.php', {
        function: 'deleteReply',
        reply_id,
        user_id
    })
}

export function addReply(song_id, user_id, reply_content) {
    return request.post('./index.php', {
        function: 'addReply',
        song_id,
        user_id,
        reply_content
    })
}

export function addGroupPurchase(user_id, content_id, content_type, originator_price) {
    return request.post('./index.php', {
        function: 'addGroupPurchase',
        user_id,
        content_id,
        content_type,
        originator_price
    })
}

export function checkGroupPurchase(user_id, content_id) {
    return request.post('./index.php', {
        function: 'checkGroupPurchase',
        user_id,
        content_id
    })
}

export function getGroupPurchaseInfo(id) {
    return request.post('./index.php', {
        function: 'getGroupPurchaseInfo',
        id
    })
}

export function finishGroupPurchase(id) {
    return request.post('./index.php', {
        function: 'finishGroupPurchase',
        id
    })
}

export function particGroupPurchase(user_id, group_purchase_id, partic_price) {
    return request.post('./index.php', {
        function: 'particGroupPurchase',
        user_id,
        group_purchase_id,
        partic_price
    })
}

export function getCollectionV1(collection_id) {
    return request.post('./index.php', {
        function: 'getCollectionV1',
        collection_id
    })
}

export function quickLogin(mobile, open_id) {
    return request.post('./index.php', {
        function: 'quickLogin',
        mobile,
        open_id
    })
}

export function checkUserHasApplyCollection(user_id, content_id, content_type) {
    return request.post('./index.php', {
        function: 'checkUserHasApplyCollection',
        user_id,
        content_id,
        content_type
    })
}