import axios from 'axios'
import router from '../router'
import {
    appLogout
} from '../utils/store.js'
import {
    getAppLoginToken,
    getAppUserId,
    getAppKidId
} from '../utils/store.js'
const service = axios.create({})
service.interceptors.request.use(config => {
    config.params = {
        ...config.params,
        'HTTP_USER_TYPE': 'PARENT',
        'user_id': getAppUserId(),
        'kid_id': getAppKidId()
    };
    config.headers = {
        ...config.headers,
        'user-type': 'Parents',
        'login-token': getAppLoginToken(),
        'user-id': getAppUserId()
    }
    config.data = {
        ...config.data,
        'HTTP_USER_TYPE': 'PARENT',
        'user_id': getAppUserId(),
        'kid_id': getAppKidId(),
    };
    return config;
})
service.interceptors.response.use((response) => {
    if (response.data && response.data.code) {
        // response.data.code = -9
        if (response.data.code == -9) {
            appLogout()
            router.replace({
                path: '/app-index'
            })
            return Promise.reject('Token过期')
        }
    }
    return response
})
export default service